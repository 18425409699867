<script setup>
import { Link } from "@inertiajs/vue3";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { UserIcon } from "@heroicons/vue/20/solid";

import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import ApplicationLogoWhite from "@/Components/ApplicationLogoWhite.vue";
import { ref } from "vue";
import Intercom from "@intercom/messenger-js-sdk";

const navigation = [
    { name: "Home", href: route("welcome") },
    { name: "For Universities", href: route("welcome.unis") },
];
//{ name: "About", href: route("welcome.about") },


const props = defineProps({
    auth: Object,
    canRegister: Boolean,
});

const mobileMenuOpen = ref(false);
Intercom({
    app_id: "g4viy2h5",
    
});
</script>
<template>
    <header class="absolute inset-x-0 top-0 z-50">
        <nav
            class="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
        >
            <div class="flex lg:flex-1">
                <a href="#" class="-m-1.5 p-1.5">
                    <span class="sr-only">Tutorie</span>
                    <ApplicationLogoWhite class="w-auto h-10" />
                </a>
            </div>
            <div class="flex lg:hidden">
                <button
                    type="button"
                    class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                    @click="mobileMenuOpen = true"
                >
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="w-6 h-6" aria-hidden="true" />
                </button>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
                <a
                    v-for="item in navigation"
                    :key="item.name"
                    :href="item.href"
                    class="text-sm font-semibold leading-6 text-white"
                    >{{ item.name }}</a
                >
            </div>
            <div class="items-center hidden lg:flex lg:flex-1 lg:justify-end">
                <Link
                    v-if="props.auth.user.name"
                    :href="route('dashboard')"
                    class="text-md font-semibold leading-6 text-gray-900 flex bg-white px-3.5 py-2 rounded-full"
                >
                    <UserIcon
                        class="bg-gray-950/30 p-[0.1rem] rounded-full h-6 w-6 text-white mr-2"
                    ></UserIcon
                    >{{ props.auth.user.name.split(" ")[0] }}</Link
                >

                <template v-else>
                    <Link
                        :href="route('login')"
                        class="font-semibold text-gray-50 hover:text-gray-200 focus:outline focus:outline-2 focus:rounded-sm focus:outline-blue-500"
                        >Log in &rarr;</Link
                    >

                    <Link
                        v-if="canRegister"
                        :href="route('register')"
                        class="font-semibold bg-white px-3.5 py-2.5 rounded-md text-gray-800 ms-4 hover:text-gray-900 focus:outline focus:outline-2 focus:rounded-sm focus:outline-blue-500"
                        >Register</Link
                    >
                </template>
            </div>
        </nav>
        <Dialog
            as="div"
            class="lg:hidden"
            @close="mobileMenuOpen = false"
            :open="mobileMenuOpen"
        >
            <div class="fixed inset-0 z-50" />
            <DialogPanel
                class="fixed inset-y-0 right-0 z-50 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
            >
                <div class="flex items-center justify-between">
                    <a href="#" class="-m-1.5 p-1.5">
                        <span class="sr-only">Tutorie</span>
                        <ApplicationLogo class="w-auto h-10" />
                    </a>
                    <button
                        type="button"
                        class="-m-2.5 rounded-md p-2.5 text-gray-700"
                        @click="mobileMenuOpen = false"
                    >
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="flow-root mt-6">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="py-6 space-y-2">
                            <a
                                v-for="item in navigation"
                                :key="item.name"
                                :href="item.href"
                                class="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                                >{{ item.name }}</a
                            >
                        </div>
                        <div class="py-6">
                            <a
                                :href="route('login')"
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >Log in</a
                            >
                            <a
                                :href="route('register')"
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >Register</a
                            >
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>
